<template>
<v-col cols="12" sm="11" md="10" lg="5" class="mx-auto">
  <v-card color="transparent" elevation="0">
    <v-col cols="12" class="text-center">
      
      <v-gravatar height="200" style="border-radius:50%;" class=""
                  :default-img="'retro'" :email="$store.state.auth.user.name"/>
      <br>
      <v-icon style="font-size:220px; margin-top:-240px;color:rgba(202, 35, 35, 0.8)">mdi-emoticon-dead</v-icon>
      <br>
      <v-card-title>
        <v-icon class="mb-1 pr-2">mdi-robot</v-icon>
        <i>Òtolife Core System</i> vous informe :
      </v-card-title>
    <v-divider class="my-3"></v-divider>
      <h1 class="red--text">
        Vous êtes mort !
      </h1>
    <v-divider class="my-3"></v-divider>

      <v-card-text class="pa-2 text-left">
        Très cher {{ $store.state.auth.user.name }},<br>
        c'est avec une infinie tristesse que nous sommes au regret de vous informer
        que vous êtes désormais, définitivement (et pour toujours) considéré comme mort, au sein de la communauté d'Òtolife.
        <br><br>
        L'ensemble de vos effets personnels, logements, et entreprises, seront prochainement
        redistribués parmis les membres encore vivant.
        <br><br>
        En tant que mort, votre partie est terminée.
        <!-- Cependant, vous pouvez continuer à communiquer avec les vivants, via notre messagerie privée compatible avec l'au-délà. -->
        <br><br>
        Merci de votre passage parmis-nous. 
        <br>
        En vous souhaitant une heureuse réincarnation dans votre prochaine vie.
        <br><br><br>
        <v-icon small class="mb-1 pr-1">mdi-robot</v-icon>
        Òtolife Core System
      </v-card-text>
      <br>

    </v-col>

    <v-divider class="mt-0 mb-3"></v-divider>
  </v-card>
</v-col>
</template>

<style></style>

<script>

//import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

//import Post from '@/components/Post.vue'
import Gravatar from 'vue-gravatar'

export default {
  name: 'empty',
  components: { 'v-gravatar' : Gravatar, },
  data: () => ({
    
    
  }),
  async mounted(){
    
  },
  methods: {
    

  },
  computed: {
    
    
  }
}
</script>
